import React from "react"
import { graphql } from "gatsby"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Cta from "@global/cards/Cta"
import Layout from "@layouts/layout"
import Block from "@global/layout/Block"
import Banner from "@global/layout/Banner"
import Card from "@global/cards/Card"
import Content from "@elements/Content"

const MealPlans = ({ data, pageContext: { title, parent } }) => {
  const mealPlans = data.mealPlans.nodes
  const nutritionPages = data.nutritionPages.nodes

  return (
    <Layout title={title} parent={parent}>
      <Seo
        title={data.content.mealPlans.meta.title}
        description={data.content.mealPlans.meta.desc}
        image={data.banner.mealPlansBanner.url}
      />
      <Banner image={data.banner.mealPlansBanner} hiddenSmall={true} />
      <Block padding="double" gutters={true}>
        <Content html={data.content.mealPlans.content} />
      </Block>
      <Block padding="both" gutters={true}>
        <CardsContainer heading="plans" modifier={"ctas"}>
          {mealPlans.map(({ title, path, ...node }) => (
            <Cta
              id={node.id}
              key={`meal-plan-${node.id}`}
              heading={title}
              subHeading={"Meal Plan"}
              image={node.banner}
              link={{
                title,
                path
              }}
            />
          ))}
        </CardsContainer>
      </Block>
      <Block padding="both" background="primary-tint">
        <Block>
          <CardsContainer
            heading="Learning About Nutrition"
            orientation="horizontal"
          >
            {nutritionPages.map(({ title, path, ...node }) => (
              <Card
                key={`nut-page-${node.id}`}
                title={title}
                image={node.banner && node.banner}
                link={{
                  title,
                  path
                }}
                id={node.id}
                modifier="simple"
                elevated={true}
              />
            ))}
          </CardsContainer>
        </Block>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query mealPlansQuery {
    mealPlans: allDatoCmsMealPlan(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        title
        id
        path
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
        }
      }
    }
    nutritionPages: allDatoCmsNutritionPage(sort: { fields: position }) {
      nodes {
        id
        title
        path
        position
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
        }
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      mealPlans {
        meta {
          title
          desc
        }
        content
      }
    }
    banner: datoCmsGlobal {
      mealPlansBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
      }
    }
  }
`

export default MealPlans
